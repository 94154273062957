import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getBaseUrl } from "../services/helpers";

// Login is called by Auth0Provider on Login, to ensure we create a "user"
// record in the database before proceeding with the redirect
export const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  debugger
  useEffect(() => {
    // We landed on here by navigating directly, or by going back
    if (!location.state) {
      loginWithRedirect()
    }

    async function fetchData() {
      let token = await getAccessTokenSilently()
      console.log(token)

      let data = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }

      fetch(`${getBaseUrl()}/api/user/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      });
    }

    fetchData()

    let locationState: any = location.state

    history.push(locationState.redirect)
  }, [location, getAccessTokenSilently, loginWithRedirect, history]);


  return (<div></div>)
}