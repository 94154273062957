import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toastError, toastInfo } from '../../app/toast_messages';
import { useAddAreaMutation, useCreateTaskMutation, useDeleteAreaMutation, useDeleteTaskMutation, useGetAreasWithTasksQuery, useUpdateAreaMutation, useUpdateTaskMutation } from '../../services/habitTreeApi';
import { isErrorWithMessage, isFetchBaseQueryError } from '../../services/helpers'
import ConfirmationModal from '../../components/ConfirmationModal';
import { BackspaceIcon, PencilAltIcon, PlusIcon, SaveIcon, XCircleIcon } from '@heroicons/react/outline';
import HabitContents from './HabitContents';
import RRule, { rrulestr } from 'rrule';
import Loading from '../../components/Loading';

/*

Area  [Save]
Todo1 [Edit] [Delete]
Todo2 [Edit] [Delete]
Todo3 [Save] [Delete]
---
Name:
Description:
Days Active (0-7):
---
[ Add Task]

*/

const EditableName = ({
  name: initialName,
  onUpdate,
  onCancel,
  loading = false,
}: {
  name: string;
  onUpdate: (name: string) => void;
  onCancel: () => void;
  loading?: boolean;
}) => {
  const [name, setName] = React.useState(initialName);

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setName(value);

  const handleUpdate = () => onUpdate(name);
  const handleCancel = () => onCancel();

  return (
    <React.Fragment>
      <div className="col-span-5">
        <input type="text" onChange={handleChange} value={name} disabled={loading} className="w-full bg-inherit outline outline-offset-2 outline-2 outline-dashed outline-rose-50" />
      </div>
      <div className="col-span-1 flex justify-end">
        <button title="Save changes" onClick={handleUpdate} disabled={loading}>
          <SaveIcon className={`${loading ? 'text-green-200' : 'text-green-700'} h-6 w-6 mx-6`} />
        </button>
        <button title="Cancel changes" onClick={handleCancel} disabled={loading}>
          <BackspaceIcon className={`h-6 w-6`} />
        </button>
      </div>
    </React.Fragment>
  );
};



const AreaTitle = ({
  name,
  areaId
}: {
  name: string;
  areaId: string
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [shouldDisplayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  const [updateArea, { isLoading: isUpdating }] = useUpdateAreaMutation();
  const [deleteArea, { isLoading: isDeleting }] = useDeleteAreaMutation();
  // const [deleteArea, { isLoading: isAreaDeleteLoading }] = deleteAreaMutation();

  const handleClickAreaDetailsSave = async (name: string) => {
    try {
      await updateArea({
        id: areaId,
        name: name
      }).unwrap()
      setIsEditMode(false);
    } catch (err: any) {
      if (isFetchBaseQueryError(err)) {
        // you can access all properties of `FetchBaseQueryError` here
        const errMsg = 'error' in err ? err.error : JSON.stringify(err.data)
        toastError("area name", errMsg);
      } else if (isErrorWithMessage(err)) {
        // you can access a string 'message' property here
        toastError("area name", err.message);
      }
    }
  }

  const handleModalSelectionChoice = async (choice: boolean) => {
    if (choice) {
      try {
        await deleteArea({
          id: areaId,
        }).unwrap()
        setIsEditMode(false);
      } catch (err: any) {
        if (isFetchBaseQueryError(err)) {
          // you can access all properties of `FetchBaseQueryError` here
          const errMsg = 'error' in err ? err.error : JSON.stringify(err.data)
          toastError("area name", errMsg);
          setDisplayConfirmationModal(false)
        } else if (isErrorWithMessage(err)) {
          // you can access a string 'message' property here
          toastError("area name", err.message);
          setDisplayConfirmationModal(false)
        }
      }
    } else {
      setDisplayConfirmationModal(false)
    }
  }


  return (

    <React.Fragment>
      {
        shouldDisplayConfirmationModal && (
          <ConfirmationModal
            handleModalSelectionChoice={handleModalSelectionChoice}
            description={`Are you sure you want to delete ${name}? This will remove all associated tasks, and cannot be undone.`}
            title="Delete Area"
          />
        )
      }
      {isEditMode ? (
        <EditableName
          name={name}
          onUpdate={(name) => handleClickAreaDetailsSave(name)}
          onCancel={() => setIsEditMode(false)}
          loading={isUpdating}
        />
        // TODO render all task settings here
      ) : (
        <React.Fragment>
          <div className="col-span-5">
            <h3>
              {name}
            </h3>
          </div>
          <div className="col-span-1 flex justify-end">
            <button title="Edit task" onClick={() => setIsEditMode(true)} disabled={isDeleting || isUpdating}>
              <PencilAltIcon className={`${isUpdating ? 'text-gray-200' : ''} h-6 w-6 mx-6`} />
            </button>
            <button title="Delete task" onClick={() => setDisplayConfirmationModal(true)} disabled={isDeleting}>
              <XCircleIcon className={`${isDeleting ? 'text-red-200' : 'text-red-700'} h-6 w-6`} />
            </button>
          </div>
        </React.Fragment>
      )
      }
    </React.Fragment>
  );

};

const TaskDetails = ({
  loading,
  recurrence,
  description,
  handleReccuranceChange,
  handleDescriptionChange
}: {
  recurrence: string,
  loading: boolean,
  description: string
  handleReccuranceChange: (arg0: string) => void
  handleDescriptionChange: (arg0: string) => void
}) => {

  let rule = rrulestr(recurrence);

  debugger
  let daysOfWeek: Record<string, boolean> = {
    "monday": rule.options.byweekday ? rule.options.byweekday.includes(0) : false,
    "tuesday": rule.options.byweekday ? rule.options.byweekday.includes(1) : false,
    "wednesday": rule.options.byweekday ? rule.options.byweekday.includes(2) : false,
    "thursday": rule.options.byweekday ? rule.options.byweekday.includes(3) : false,
    "friday": rule.options.byweekday ? rule.options.byweekday.includes(4) : false,
    "saturday": rule.options.byweekday ? rule.options.byweekday.includes(5) : false,
    "sunday": rule.options.byweekday ? rule.options.byweekday.includes(6) : false,
  }

  const onDayOfWeekSelect = (e: any) => {
    daysOfWeek[e.target.id] = e.target.checked

    let byDayString = ""
    Object.entries(daysOfWeek).forEach(([dayOfWeek, checked], index) => {
      if (checked) {
        // get first 2 characters of dayOfWeek
        byDayString += dayOfWeek.substring(0, 2).toUpperCase() + ","
      }
    })
    let rruleTemplate = `RRULE:FREQ=DAILY;COUNT=1;INTERVAL=1;WKST=MO;BYDAY=`

    if (byDayString.length > 0) {
      // remove command at end of byDayString
      byDayString = byDayString.slice(0, byDayString.length - 1)
      // Update rrule string
      rruleTemplate += byDayString
    } else {
      // No days selected
      rruleTemplate = `RRULE:FREQ=DAILY;COUNT=1;INTERVAL=1;WKST=MO`
    }


    debugger
    handleReccuranceChange(rruleTemplate)
  }

  return (
    <React.Fragment>
      <div className="col-span-6 pt-4">
        <h3 className="font-semibold"> Description </h3>
      </div>
      <div className="col-span-5">
        <input type="text" onChange={(e) => { handleDescriptionChange(e.target.value) }} value={description} disabled={loading} className="w-full bg-inherit outline outline-offset-2 outline-2 outline-dashed outline-rose-50" />
      </div>

      <div className="col-span-6 pt-4">
        <h3 className="font-semibold"> Recurrence </h3>
      </div>

      <div className="col-span-6">
        <div className="flex flex-wrap">
          {Object.entries(daysOfWeek).map(([day, checked]) => {
            return (
              <React.Fragment>
                <div className="flex items-center h-5">
                  <input
                    id={day}
                    name={day}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                    disabled={loading}
                    // onClick={onDayOfWeekSelect}
                    onChange={onDayOfWeekSelect}
                    defaultChecked={checked}
                  />
                  <div className="ml-1 mr-3 text-sm select-none">
                    <label htmlFor={day} className="font-medium text-gray-700">
                      {day}
                    </label>
                  </div>
                </div>
              </React.Fragment>
            )
          })}

        </div>
      </div>
    </React.Fragment >

  );
}

const EditableTask = ({
  id,
  name,
  recurrence,
  description,
  defaultEditState,
}: {
  id: string,
  name: string,
  recurrence: string,
  description: string,
  defaultEditState: boolean,
}) => {

  const [isEditing, setIsEditing] = React.useState(defaultEditState);
  const [selectedReccurance, setReccurance] = React.useState(recurrence);
  const [currentDescription, setDescription] = React.useState(description);

  const [updateTask, { isLoading: isUpdating }] = useUpdateTaskMutation();
  const [deleteTask, { isLoading: isDeleting }] = useDeleteTaskMutation();

  return (
    <React.Fragment>
      {isEditing ? (
        <React.Fragment>
          <EditableName
            name={name}
            onUpdate={(name) =>
              updateTask({ id, name, recurrence: selectedReccurance, description: currentDescription }).unwrap()
                .then((result) => {
                  // handle the success!
                  console.log('Update Result', result);
                  setIsEditing(false);
                })
                .catch((error) => console.error('Update Error', error))
            }
            onCancel={() => setIsEditing(false)}
            loading={isUpdating}
          />
          <TaskDetails
            recurrence={selectedReccurance}
            description={currentDescription}
            loading={isUpdating}
            handleReccuranceChange={(reccurance) => {
              setReccurance(reccurance);
            }}
            handleDescriptionChange={(description) => {
              setDescription(description);
            }}
          />
          {/* <HabitContents

          /> */}
        </React.Fragment>
        // TODO render all task settings here
      ) : (
        <React.Fragment>
          <div className="col-span-5">
            <h3>
              {name}
            </h3>
          </div>
          <div className="col-span-1 flex justify-end">
            <button title="Edit task" onClick={() => setIsEditing(true)} disabled={isDeleting || isUpdating}>
              <PencilAltIcon className={`${isUpdating ? 'text-gray-200' : ''} h-6 w-6 mx-6`} />
            </button>
            <button title="Delete task" onClick={() => deleteTask({ id })} disabled={isDeleting}>
              <XCircleIcon className={`${isDeleting ? 'text-red-200' : 'text-red-700'} h-6 w-6`} />
            </button>
          </div>
        </React.Fragment>
      )
      }
    </React.Fragment>
  );

}

const AreasWithTasksList = () => {
  const { data: areas, isLoading } = useGetAreasWithTasksQuery();
  const [createArea, { isLoading: isCreatingArea }] = useAddAreaMutation();
  const [createTask, { isLoading: isCreatingTask }] = useCreateTaskMutation();
  const [recentlyCreatedTaskId, setRecentlyCreatedTaskId] = React.useState("");

  if (isLoading) {
    return <div className=""><Loading /></div>;
  }

  if (!areas) {
    return <div className="">No Areas :(</div>;
  }

  return (
    <div className="pb-3">
      {
        areas.map((area) => {
          return (
            <div key={area.id} className="bg-gray-900 text-white px-3 py-2 my-3">
              <div className="col-span-6 grid grid-cols-6 m-6">
                <AreaTitle key={area.id} name={area.name} areaId={area.id} />
              </div>
              {area.tasks.map((task, index) => {
                console.log("Rendered Task ", task.id);
                if (task.id === recentlyCreatedTaskId) {
                  debugger
                  // setRecentlyCreatedTaskId("");
                }

                return (<div key={task.id} className={`text-black ${index % 2 ? "bg-gray-100" : "bg-gray-300"} col-span-6 grid grid-cols-6 p-6`}>
                  <EditableTask id={task.id} name={task.name} recurrence={task.recurrence} defaultEditState={task.id === recentlyCreatedTaskId} description={task.description} />
                </div>)

              })}
              <button onClick={() => {
                createTask({ name: "New habit (rename me)", areaId: area.id, description: "New task" }).unwrap()
                  .then((result) => {
                    console.log('Create Result', result);
                    debugger
                    setRecentlyCreatedTaskId(result.id)
                    // Save the ID of new task, so we can render it in edit mode
                  }
                  )
                  .catch((error) => console.error('Create Error', error))
              }} className={`bg-gray-200 text-black p-4 w-full flex`}>
                <div className="flex text-1xl font-bold" >
                  <span>Add new habit to area</span>
                  <PlusIcon className={` h-6 w-6 ml-3 text-green-400`} />
                </div>
              </button>
            </div>
          )
        })
      }

      <div className="my-3">
        <button onClick={() => {

          if (areas.length >= 10) {
            return toastInfo("Sorry a maximum of 10 areas are currently supported");
          }
          createArea({ name: "New Area" }).unwrap()
            .then((result) => {
              // handle the success!
              console.log('Create Result', result);
            }
            )
            .catch((error) => console.error('Create Error', error))

        }} className="bg-gray-900 text-white px-3 py-2 my-3 w-full flex align-middle justify-center">
          <div className="flex align-middle text-2xl font-medium" >
            <span>Create new area</span>
            <PlusIcon className={` h-6 w-6 mt-2 ml-3 text-green-400`} />
          </div>
        </button>

      </div>

    </div >
  );
};

export const HabitManager = () => {
  return (
    <div>
      <div className="row">
        <AreasWithTasksList />
      </div>
    </div>
  );
};

export default HabitManager;
