// src/auth/auth0-provider-with-history.js
import { useHistory } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }: any) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  //  You can request an access token in a format that the API can verify by passing the audience and scope props to Auth0Provider.
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

  const history = useHistory();

  const onRedirectCallback = async (appState: any) => {
    history.push({
      pathname: "/login",
      state: {
        redirect: appState?.returnTo || window.location.pathname
      }
    });
  };

  // When you don't pass a scope prop to Auth0Provider as in the example above, the React SDK defaults to the OpenID Connect Scopes: openid profile email.
  // see more here - https://auth0.com/blog/complete-guide-to-react-user-authentication/
  return (
    <Auth0Provider
      useRefreshTokens={true}
      cacheLocation="localstorage"
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;