import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../app/hooks";
import { useGetAreasWithTasksQuery } from "../../services/habitTreeApi";
import { interpolateColor } from "../../utils/interpolateColor";
import styles from "./Overview.module.css";
import { LinkIcon } from '@heroicons/react/outline'
import { ReactComponent as MeadowGrass } from '../../assets/meadow_grass.svg';
import { useGetUserQuery } from "../../services/userApi";
import { useLocation } from 'react-router-dom'
import React from "react";
import Loading from "../../components/Loading";

let developmentAreas =
  [
    {
      name: "Hobbies & Recreation",
      health: 100,
      tasksCompletedToday: 1,
      totalTotalRequiredToday: 5,
    },
    {
      name: "Mind",
      health: 100,
      tasksCompletedToday: 1,
      totalTotalRequiredToday: 2,
    },
    {
      name: "Body",
      health: 100,
      tasksCompletedToday: 1,
      totalTotalRequiredToday: 5,
    },
    {
      name: "Personal Learning",
      health: 100,
      tasksCompletedToday: 5,
      totalTotalRequiredToday: 5,
    },
    {
      name: "Friends & Family",
      health: 100,
      tasksCompletedToday: 5,
      totalTotalRequiredToday: 5,
    }
  ]

const intToPercent = (int: number): string => {
  return int + "%";
}

// make a green hex brighter
// const makeGreen = (hex: string): string => {
//   const rgb = hexToRgb(hex);
//   const newRgb = {
//     r: rgb.r + 50,
//     g: rgb.g + 50,
//     b: rgb.b + 50,
//   };
//   return rgbToHex(newRgb);
// }

// Get random number between
const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const OverviewManager = () => {

  const size = useWindowSize();
  const { push } = useHistory();
  const { data: areasWithTasks, error, isError, isLoading } = useGetAreasWithTasksQuery();
  const { data: userData, isLoading: isLoadingUserData, isError: isLoadingUserErrored } = useGetUserQuery()
  let location = useLocation();
  console.log(location)

  debugger
  if (isLoading || isLoadingUserData) {
    return <Loading />
  }

  if (isError || isLoadingUserErrored) {
    return <div className="container m-auto">Error fetching data!</div>;
  }

  if (!areasWithTasks || !userData) {
    return <div className="container m-auto">No Areas :(</div>;
  }

  if (!areasWithTasks.length) {
    return <div className="container m-auto">No areas created yet, head over to to
      <button
        className="inline-flex items-center justify-center px-5 py-2 mx-3 border border-transparent text-base font-medium rounded-full text-white bg-gray-600 hover:bg-gray-700"
        onClick={() =>
          push("/settings")
        }
      >
        Settings
      </button>

      start creating and visualing your areas/habits</div>;
  }


  // checkw if the window is small enough to show the areas in a list
  // sm(640px)	max - width: 640px;
  // md(768px)	max - width: 768px;
  // lg(1024px)	max - width: 1024px;
  // xl(1280px)	max - width: 1280px;
  // 2xl(1536px)	max - width: 1536px;

  // Reserve 50% for all areas, and divide by the number of areas
  // debugger
  let topHeight = areasWithTasks.length > 5 ? (80 / areasWithTasks.length) : (70 / areasWithTasks.length)

  // TODO sort for mobile
  let topWidth = 50;
  let topOffset = 10

  // debugger
  if (size.width < 640) {
    debugger
    topWidth = 80;
  }
  else if (size.width < 768) {
    topWidth = 80;
  }
  else if (size.width < 1024) {
    topWidth = 80;
  }
  else if (size.width < 1280) {
  }
  else if (size.width < 1536) {
  }
  else {

  }

  let topLeft = (100 - topWidth) / 2;


  let actualUser = userData as any
  // Green into brown
  let foliageColor = interpolateColor([21, 179, 82], [179, 69, 12], actualUser.health / 100)

  return (
    <div className={styles.background + ""}>

      <React.Fragment>

        <div className="fixed right-5 bottom-2 text-lg z-50 font-bold" >{actualUser.health}%</div>
        <MeadowGrass
          style={{
            // Limit  to -50px to 50px
            bottom: actualUser.health > 50 ? -110 + actualUser.health + "px" : "-50px",
            color: foliageColor,
            left: 100 + "px",
          }}
          fill={foliageColor}
          className={`${styles.meadowgrass1}`} />
        <MeadowGrass
          style={{
            // Limit  to -50px to 50px
            bottom: actualUser.health > 50 ? -110 + actualUser.health + "px" : "-50px",
            color: foliageColor,
            left: 300 + "px",

          }}
          fill={foliageColor}
          className={`${styles.meadowgrass1}`} />

        <MeadowGrass
          style={{
            // Limit  to -50px to 50px
            bottom: actualUser.health > 50 ? -110 + actualUser.health + "px" : "-50px",
            color: foliageColor,
            left: 600 + "px",

          }}
          fill={foliageColor}
          className={`${styles.meadowgrass1}`} />

        <MeadowGrass
          style={{
            // Limit  to -50px to 50px
            bottom: actualUser.health > 50 ? -110 + actualUser.health + "px" : "-50px",
            color: foliageColor,
            left: 900 + "px",
          }}
          fill={foliageColor}
          className={`${styles.meadowgrass1}`} />

        <MeadowGrass
          style={{
            // Limit  to -50px to 50px
            bottom: actualUser.health > 50 ? -110 + actualUser.health + "px" : "-50px",
            color: foliageColor,
            left: 1400 + "px",
          }}
          fill={foliageColor}
          className={`${styles.meadowgrass1}`} />
        <MeadowGrass
          style={{
            // Limit  to -50px to 50px
            bottom: actualUser.health > 50 ? -110 + actualUser.health + "px" : "-50px",
            color: foliageColor,
            left: 1800 + "px",
          }}
          fill={foliageColor}
          className={`${styles.meadowgrass1}`} />
        <MeadowGrass
          style={{
            // Limit  to -50px to 50px
            bottom: actualUser.health > 50 ? -110 + actualUser.health + "px" : "-50px",
            color: foliageColor,
            left: 2500 + "px",
          }}
          fill={foliageColor}
          className={`${styles.meadowgrass1}`} />
      </React.Fragment>


      {/* If user health > 70, sun will come out */}
      {
        actualUser.health >= 70 ? (
          // When HP is at 70, sun will go in by 100PX, then gradually out by 100PX
          <div style={{ top: -(100 - actualUser.health) * 3, right: -(100 - actualUser.health) * 3 }} className={`${styles.sun}`}>
            <div className={styles.ray_box}>
              <div className={`${styles.ray} ${styles.ray1}`}></div>
              <div className={`${styles.ray} ${styles.ray2}`}></div>
              <div className={`${styles.ray} ${styles.ray3}`}></div>
              <div className={`${styles.ray} ${styles.ray4}`}></div>
              <div className={`${styles.ray} ${styles.ray5}`}></div>
              <div className={`${styles.ray} ${styles.ray6}`}></div>
              <div className={`${styles.ray} ${styles.ray7}`}></div>
              <div className={`${styles.ray} ${styles.ray8}`}></div>
              <div className={`${styles.ray} ${styles.ray9}`}></div>
              <div className={`${styles.ray} ${styles.ray10}`}></div>
            </div>
          </div>

        ) : null
      }

      <div className={styles.trunk}></div>
      {
        areasWithTasks.map((area, index) => {

          // In reality, in order to calculate total tasks required today, we need a flag
          // for ach task to indicate whether it's active today. We should rely on this flag
          // rather than trying to work it our by looking at daysOfWeek active, to avoid
          // any timezone issues.

          // Get all tasks that are completed
          let completedTasks = area.tasks.filter((task) => task.completed).length
          // get total amount of tasks in area
          let totalTasksRequiredToday = area.tasks.filter((task) => task.isDueToday).length

          let hasCompletedAllTasksToday = completedTasks === totalTasksRequiredToday



          // let res = `rgb(` + interpolateColor([20, 83, 45], [120, 53, 15], area.health / 100) + `)`
          // debugger

          let colorPalette = ["grey", "red", "orange", "yellow", "green", "blue", "indigo", "violet"]

          let percentagePerColour = 100 / area.comboCount

          // TODO - Dynamically create a boxShadow
          let counter = 0
          let boxShadow = ""
          while (counter < area.comboCount) {
            counter++
            let colorIndex = counter % 5
            // create box shadow, with each color in palette
            let boxShadow = `0 0 ${percentagePerColour}% ${colorPalette[counter]}`
          }

          // border - image:   linear-gradient(to right, grey 25%, yellow 25%, yellow 50%,red 50%, red 75%, teal 75%) 5;
          let topClass = "top-1/3"
          if (areasWithTasks.length >= 3) {
          } else if (areasWithTasks.length >= 6) {
            topClass = "mt-2"
          }

          const style = { "--value": 70 } as React.CSSProperties


          return (
            <div style={{
              height: intToPercent(topHeight),
              // Make sure they all touching using + 1
              top: intToPercent(((index * (topHeight - 2)) + 1) + topOffset),
              width: intToPercent(topWidth + getRandomInt(-5, 5)),
              left: intToPercent(topLeft + (index % 2 ? getRandomInt(-7.5, -5) : getRandomInt(5, 7.5))),
              backgroundColor: interpolateColor([22, 101, 52], [120, 53, 15], area.health / 100),
              // Border
              // outline: `${area.comboCount || 1}px solid white`,
              // outline: "5px solid hsl(0, 0%, 60%)",
              // boxShadow: `
              //   0 0 0 8px brown,
              //   0 0 0 12px blue,
              //   0 0 0 20px yellow,
              //   0 0 0 25px green
              // `,
            }} className={styles.top + ` absolute`}>

              <div className="absolute w-full h-full">
                <div className={`relative px-1 md:px-4 flex align-center h-full`}>
                  <div className="flex flex-row flex-wrap m-auto px-3 md:px-12 w-full">
                    <div className="font-bold text-white flex justify-between w-full pb-1">
                      <div className="">
                        {area.name.toUpperCase()} ({area.health + `%`})
                      </div>
                      <div className="flex">STREAK - {area.comboCount}</div>
                    </div>

                    {/* <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                      <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: "45%" }}> 45%</div>
                    </div> */}

                    <div onClick={(id) => push(`/todo_today`)} className={`w-full text-white`}>

                      {totalTasksRequiredToday === 0 ? <div>NO TASKS DUE TODAY</div> : (
                        <div>
                          <div className="mb-1 text-base font-medium ">{completedTasks}/{totalTasksRequiredToday} HABITS COMPLETE</div>
                          <div className="w-full bg-gray-200 rounded-full h-2.5 bg-gray-700">
                            <div className="bg-gray-600 h-2.5 rounded-full bg-gray-300" style={{ width: intToPercent(completedTasks / totalTasksRequiredToday * 100) }}></div>
                          </div>
                        </div>
                      )}

                      {/* {totalTasksRequiredToday > 0 && hasCompletedAllTasksToday ?
                        <div>{completedTasks}/{totalTasksRequiredToday} HABITS COMPLETE</div>
                        : <div>
                          <div className="mb-1 text-base font-medium dark:text-white">{completedTasks}/{totalTasksRequiredToday} HABITS COMPLETE</div>
                          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-gray-600 h-2.5 rounded-full dark:bg-gray-300" style={{ width: intToPercent(completedTasks / totalTasksRequiredToday * 100) }}></div>
                          </div>
                        </div>} */}

                    </div>

                    {/* {
                      hasCompletedAllTasksToday ? (
                        <div>No tasks to complete today</div>
                      ) : (
                        <div>
                          <div className="mb-1 text-base font-medium dark:text-white">Default</div>
                          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-gray-600 h-2.5 rounded-full dark:bg-gray-300" style="width: 45%"></div>
                          </div>
                        </div>
                      )
                    }

                    {
                      totalTasksRequiredToday > 0
                        ? (
                          <div>Test</div>
                        )
                        : (
                          <div>No tasks due today</div>
                        )
                    } */}


                    {/*

                    <div className={`${styles.radialprogress}`} style={{ ...style }}>100%</div>

                    <div onClick={(id) => push(`/todo_today`)} className={`${!hasCompletedAllTasksToday ? "text-black bg-white " : "text-white "} relative w-full h-6 rounded-full`}>
                      <div className="left-4 absolute text-center font-bold">{`${completedTasks}/${totalTasksRequiredToday}`} HABITS COMPLETE</div>


                      <div className={"h-full rounded-full " + `${hasCompletedAllTasksToday ? "bg-green-900" : "bg-gray-900"}`} style={{
                        width: intToPercent(completedTasks / totalTasksRequiredToday * 100),
                      }}>
                      </div>
                    </div> */}

                  </div>

                </div >
              </div >

            </div >
          )
        })
      }
      <div style={{ background: foliageColor }} className={styles.mound}></div>
    </div >
  )

}

