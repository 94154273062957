// https://stackoverflow.com/questions/16360533/calculate-color-hex-having-2-colors-and-percent-position
// export function interpolateColor(c0: string = 'FF0000', c1: string = 'FF0000', f: number) {
//   // @ts-ignore
//   c0 = c0.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * (1 - f))
//   // @ts-ignore
//   c1 = c1.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * f)
//   // @ts-ignore
//   let ci = [0, 1, 2].map(i => Math.min(Math.round(c0[i] + c1[i]), 255))
//   return ci.reduce((a, v) => ((a << 8) + v), 0).toString(16).padStart(6, "0")
// }

//colorChannelA and colorChannelB are ints ranging from 0 to 255
// @ts-ignore
export function colorChannelMixer(colorChannelA, colorChannelB, amountToMix) {
  var channelA = colorChannelA * amountToMix;
  var channelB = colorChannelB * (1 - amountToMix);
  // @ts-ignore

  return parseInt(channelA + channelB);
}
//rgbA and rgbB are arrays, amountToMix ranges from 0.0 to 1.0
//example (red): rgbA = [255,0,0]
// @ts-ignore

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// @ts-ignore

export function interpolateColor(rgbA, rgbB, amountToMix) {
  // @ts-ignore

  var r = colorChannelMixer(rgbA[0], rgbB[0], amountToMix);
  // @ts-ignore

  var g = colorChannelMixer(rgbA[1], rgbB[1], amountToMix);
  // @ts-ignore

  var b = colorChannelMixer(rgbA[2], rgbB[2], amountToMix);

  return rgbToHex(r, g, b);

  // return "rgb(" + r + "," + g + "," + b + ")";

}


// export const interpolateColor = (
//   // @ts-ignore
//   color1,
//   // @ts-ignore
//   color2,
//   // @ts-ignore
//   ratio,
// ) => {
//   // @ts-ignore
//   const hex = (color) => {
//     const colorString = color.toString(16);
//     return colorString.length === 1 ? `0${colorString}` : colorString;
//   };

//   const r = Math.ceil(
//     parseInt(color2.substring(0, 2), 16) * ratio
//     + parseInt(color1.substring(0, 2), 16) * (1 - ratio),
//   );
//   const g = Math.ceil(
//     parseInt(color2.substring(2, 4), 16) * ratio
//     + parseInt(color1.substring(2, 4), 16) * (1 - ratio),
//   );
//   const b = Math.ceil(
//     parseInt(color2.substring(4, 6), 16) * ratio
//     + parseInt(color1.substring(4, 6), 16) * (1 - ratio),
//   );

//   return hex(r) + hex(g)
// }