// create a component which will call useGetAreasWithTasksQuery, and render each one with a checkbox
import React from 'react';
import Loading from '../../components/Loading';
import { Task, useGetTasksQuery, useUpdateTaskMutation } from '../../services/habitTreeApi';
import { CheckCircleIcon } from '@heroicons/react/solid'

// Create component which renders the task
const TaskItem = ({ task }: { task: Task }) => {
  const [updateTask, { isLoading: isUpdating }] = useUpdateTaskMutation();

  console.log(task.id)
  return (
    <div className="grid grid-cols-6 my-4">
      <div className="col-span-1 flex justify-items-center items-center">
        <input
          id={task.id}
          name={task.id}
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          defaultChecked={task.completed}
          disabled={isUpdating}
          onChange={(e) => {
            debugger
            updateTask({ completed: e.target.checked, id: task.id, areaId: task.areaId }).unwrap()
              .then((result) => {
              })
              .catch((error) => console.error('Update Error', error))
          }}
        />
      </div>
      <div className="col-span-5">
        <div className="flex flex-col">
          <div className="ml-1 mr-3 text-sm select-none">
            <label htmlFor={task.id} className="font-extrabold text-gray-700 tracking-wide text-lg">
              {task.name}
            </label>
          </div>
          <div className="ml-1 mr-3 text-sm select-none">
            <label htmlFor={task.id} className="font-medium text-gray-700">
              {task.description}
            </label>
          </div>

        </div>
      </div>
    </div>
  )
}


export default function TodoTodayManager() {

  const { data, isLoading } = useGetTasksQuery();

  // TODO - This will block rendering of all todo's
  // every time we reload, we need to optimise this
  if (isLoading) {
    return <Loading />
  }

  if (!data) {
    return <div className="">Unable to load tasks :(</div>;
  }

  // See how many tasks are expected to be completed today
  let expectedComplete = data.filter(task => task.isDueToday).length;
  let totalComplete = data.filter(task => task.completed).length;

  return (
    <div className="">
      <div className="col-span-6 pt-4 mb-8 font-semibold text-3xl">
        <h3 className=""> Due Today </h3>
      </div>

      {
        expectedComplete > 0 && (expectedComplete === totalComplete) && (
          <div className="py-4 bg-green-600 text-white my-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="ml-2 h-5 w-5 text-white" aria-hidden="true" />
              </div>
              <div className="ml-3">
                {/* @ts-ignore */}
                <p className="text-sm font-medium px-1">Nice job, all habits complete! Your overview / individual areas health will be updated at the end of the day ({Math.round(Math.abs(new Date() - new Date().setHours(24, 0, 0, 0)) / 36e5)} hours) </p>
              </div>

            </div>
          </div>
        )
      }
      {data.map((task) => {
        if (task.isDueToday) {

          return (
            <React.Fragment key={task.id}>
              <hr />
              <TaskItem task={task} />
            </React.Fragment>
          )
        }

        // if (task.isDueToday && task.completed) {
        //   return (
        //     <div>
        //       <div className="col-span-6 pt-4">
        //         <h3 className="font-semibold"> Completed </h3>
        //       </div>
        //       <div key={task.id}>
        //         <input type="checkbox" checked />
        //         {task.name}
        //       </div>
        //     </div>
        //   );
        // }

        // return []
      })}

    </div >
  )
}
