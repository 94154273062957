import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { sec } from '../app/security';
import { getBaseUrl } from './helpers';

// RESOURCES
// https://redux-toolkit.js.org/rtk-query/usage/examples
// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced

export interface Area {
  id: string;
  name: string;
  health: number;
  comboCount: number;
}

type AreaResponse = Area[];

export interface Task {
  id: string;
  areaId: string;
  name: string;
  description: string;
  recurrence: string;
  completed: boolean;
  isDueToday: boolean;

  createdAt: Date;
}

type TaskResponse = Task[];

export interface AreasWithTask extends Area {
  tasks: Task[];
}

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: `${getBaseUrl()}/api/v1/`,
  prepareHeaders: async (headers: any) => {
    // https://github.com/reduxjs/redux-toolkit/issues/1331
    const access_token = await sec.getAccessTokenSilently()();
    if (access_token) {
      headers.set('Authorization', `Bearer ${access_token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const habitTreeApi = createApi({
  reducerPath: 'habitTreeApi', // We only specify this because there are many services. This would not be common in most applications
  tagTypes: ['Areas', 'Tasks'],
  baseQuery: baseQueryWithRetry,
  endpoints: (build) => ({
    // getAreas: build.query<AreaResponse, void>({
    //   query: () => ({ url: 'areas' }),
    //   providesTags: ['Areas'],
    // }),
    addArea: build.mutation<Area, Partial<Area>>({
      query: (body) => ({
        url: `areas`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Areas'],
    }),
    getAreasWithTasks: build.query<AreasWithTask[], void>({
      // highlight-start
      providesTags: ['Areas'],
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        const areas = await baseQuery('areas')
        const tasks = await baseQuery('tasks')

        if (areas.error || tasks.error) {
          return { error: { status: 500, statusText: 'Unable to fetch areas/tasks', data: areas.error } }
        } else {
          // For each area, find all associated tasks

          let areaResult = areas.data as AreaResponse
          let taskResult = tasks.data as TaskResponse

          const areasWithTasks = areaResult.map(area => {
            const tasksForArea = taskResult.filter(task => task.areaId === area.id)
            return { ...area, tasks: tasksForArea }
          })

          return { data: areasWithTasks }
        }
      }
    }),
    getTasks: build.query<TaskResponse, void>({
      query: () => ({ url: 'tasks' }),
      providesTags: ['Areas'],
    }),
    getTasksDueToday: build.query<Task[], void>({
      query: () => ({ url: 'tasks?isDueToday=true' }),
      providesTags: ['Tasks'],
    }),
    createTask: build.mutation<Task, Partial<Task>>({
      query: (body) => ({
        url: `tasks`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Areas'],
    }),
    updateTask: build.mutation<Task, Partial<Task>>({
      query: (body) => ({
        url: `tasks`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Areas'],
    }),
    deleteTask: build.mutation<Task, Partial<Task>>({
      query: (body) => ({
        url: `tasks/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Areas'],
    }),
    updateArea: build.mutation<Task, Partial<Area>>({
      query: (body) => ({
        url: `areas`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Areas'],
    }),
    deleteArea: build.mutation<Task, Partial<Area>>({
      query: (body) => ({
        url: `areas/${body.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Areas'],
    }),
  })
});

export const {
  useAddAreaMutation,
  // useGetAreasQuery,
  useUpdateAreaMutation,
  useDeleteAreaMutation,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useGetTasksQuery,
  useGetTasksDueTodayQuery,
  // Aggregate,
  useGetAreasWithTasksQuery,
} = habitTreeApi;
